var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"muskoskeletal"}},[_c('h5',[_vm._v("Muscoskeletal / Motor Assessment")]),_c('hr'),(_vm.getDataLoading)?_c('div',{staticClass:"text-center mt-4 mb-3"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Large Spinner"}}),_c('br'),_c('label',[_vm._v(" Please Wait ")])],1):_vm._e(),(_vm.notification.isActive)?_c('notification',{attrs:{"type":_vm.notification.type,"message":_vm.notification.message}}):_vm._e(),(
      _vm.getDataSuccess &&
      _vm.handleLogicStatement('muscoskeletalMotorAssessmentInfo', 'visibility')
    )?_c('notification',{staticClass:"mb-3",attrs:{"type":"info","message":_vm.infoText}}):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.saveDataLoading}},[_c('fieldset',{attrs:{"disabled":!_vm.canManagePatient}},[(_vm.getDataSuccess)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.requestSaveForm.apply(null, arguments)}}},[(
            _vm.handleLogicStatement(
              'dateOfmuscoskeletalMotorAssessment',
              'visibility'
            )
          )?_c('datepicker',{attrs:{"label":"Date of Assessment","name":"dateOfmuscoskeletalMotorAssessment","placeholder":"Enter Date of Assessment","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement(
            'dateOfmuscoskeletalMotorAssessment',
            'disable'
          )},on:{"changeEvent":_vm.updateData},model:{value:(_vm.content.muscoskeletalMotorAssessment.dateOfmuscoskeletalMotorAssessment),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment, "dateOfmuscoskeletalMotorAssessment", $$v)},expression:"content.muscoskeletalMotorAssessment.dateOfmuscoskeletalMotorAssessment"}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(
                _vm.handleLogicStatement(
                  'proximalWeaknessInUpperExtremities.isPresent',
                  'visibility'
                )
              )?_c('dropdown',{attrs:{"label":"Proximal muscle weakness in upper extremities","name":"proximalWeaknessInUpperExtremities.isPresent","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(_vm.content.muscoskeletalMotorAssessment.proximalWeaknessInUpperExtremities.isPresent),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment.proximalWeaknessInUpperExtremities, "isPresent", $$v)},expression:"content.muscoskeletalMotorAssessment.proximalWeaknessInUpperExtremities.isPresent"}}):_vm._e()],1),(
              _vm.handleLogicStatement(
                'proximalWeaknessInUpperExtremities.theStatus',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Status","name":"proximalWeaknessInUpperExtremities.theStatus","options":_vm.options.dropStatusList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities.theStatus
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities, "theStatus", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .proximalWeaknessInUpperExtremities.theStatus\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'proximalWeaknessInUpperExtremities.dateIfNew',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('datepicker',{attrs:{"label":"Date of Diagnosis","name":"proximalWeaknessInUpperExtremities.dateIfNew","placeholder":"Enter Date of Diagnosis","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement(
                'proximalWeaknessInUpperExtremities.dateIfNew',
                'disable'
              )},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities.dateIfNew
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities, "dateIfNew", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .proximalWeaknessInUpperExtremities.dateIfNew\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'proximalWeaknessInUpperExtremities.isPriorPompe',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Was diagnosis made prior to of Pompe Therapy?","name":"proximalWeaknessInUpperExtremities.isPriorPompe","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities.isPriorPompe
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities, "isPriorPompe", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .proximalWeaknessInUpperExtremities.isPriorPompe\n              "}})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Distal muscle weakness in upper extremities","name":"distalWeaknessInUpperExtremities.isPresent","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.isPresent
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities, "isPresent", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInUpperExtremities.isPresent\n              "}})],1),(
              _vm.handleLogicStatement(
                'distalWeaknessInUpperExtremities.theStatus',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Status","name":"distalWeaknessInUpperExtremities.theStatus","options":_vm.options.dropStatusList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.theStatus
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities, "theStatus", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInUpperExtremities.theStatus\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'distalWeaknessInUpperExtremities.dateIfNew',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('datepicker',{attrs:{"label":"Date of Diagnosis","name":"distalWeaknessInUpperExtremities.dateIfNew","placeholder":"Enter Date of Diagnosis","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement(
                'distalWeaknessInUpperExtremities.dateIfNew',
                'disable'
              )},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.dateIfNew
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities, "dateIfNew", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInUpperExtremities.dateIfNew\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'distalWeaknessInUpperExtremities.isPriorPompe',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Was diagnosis made prior to of Pompe Therapy?","name":"distalWeaknessInUpperExtremities.isPriorPompe","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.isPriorPompe
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities, "isPriorPompe", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInUpperExtremities.isPriorPompe\n              "}})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Proximal muscle weakness in lower extremities","name":"proximalWeaknessInLowerExtremities.isPresent","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.isPresent
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities, "isPresent", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .proximalWeaknessInLowerExtremities.isPresent\n              "}})],1),(
              _vm.handleLogicStatement(
                'proximalWeaknessInLowerExtremities.theStatus',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Status","name":"proximalWeaknessInLowerExtremities.theStatus","options":_vm.options.dropStatusList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.theStatus
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities, "theStatus", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .proximalWeaknessInLowerExtremities.theStatus\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'proximalWeaknessInLowerExtremities.dateIfNew',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('datepicker',{attrs:{"label":"Date of Diagnosis","name":"proximalWeaknessInLowerExtremities.dateIfNew","placeholder":"Enter Date of Diagnosis","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement(
                'proximalWeaknessInLowerExtremities.dateIfNew',
                'disable'
              )},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.dateIfNew
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities, "dateIfNew", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .proximalWeaknessInLowerExtremities.dateIfNew\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'proximalWeaknessInLowerExtremities.isPriorPompe',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Was diagnosis made prior to of Pompe Therapy?","name":"proximalWeaknessInLowerExtremities.isPriorPompe","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.isPriorPompe
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities, "isPriorPompe", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .proximalWeaknessInLowerExtremities.isPriorPompe\n              "}})],1):_vm._e()]),_c('div',{staticClass:"row"},[(
              _vm.handleLogicStatement(
                'distalWeaknessInLowerExtremities.isPresent',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Distal muscle weakness in lower extremities","name":"distalWeaknessInLowerExtremities.isPresent","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.isPresent
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities, "isPresent", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInLowerExtremities.isPresent\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'distalWeaknessInLowerExtremities.theStatus',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Status","name":"distalWeaknessInLowerExtremities.theStatus","options":_vm.options.dropStatusList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.theStatus
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities, "theStatus", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInLowerExtremities.theStatus\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'distalWeaknessInLowerExtremities.dateIfNew',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('datepicker',{attrs:{"label":"Date of Diagnosis","name":"distalWeaknessInLowerExtremities.dateIfNew","placeholder":"Enter Date of Diagnosis","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement(
                'distalWeaknessInLowerExtremities.dateIfNew',
                'disable'
              )},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.dateIfNew
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities, "dateIfNew", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInLowerExtremities.dateIfNew\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'distalWeaknessInLowerExtremities.isPriorPompe',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Was diagnosis made prior to of Pompe Therapy?","name":"distalWeaknessInLowerExtremities.isPriorPompe","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.isPriorPompe
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities, "isPriorPompe", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .distalWeaknessInLowerExtremities.isPriorPompe\n              "}})],1):_vm._e()]),_c('div',{staticClass:"row"},[(
              _vm.handleLogicStatement('weaknessInTrunk.isPresent', 'visibility')
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Muscle weakness in trunk","name":"weaknessInTrunk.isPresent","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment.weaknessInTrunk.isPresent
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment.weaknessInTrunk, "isPresent", $$v)},expression:"\n                content.muscoskeletalMotorAssessment.weaknessInTrunk.isPresent\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement('weaknessInTrunk.theStatus', 'visibility')
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Status","name":"weaknessInTrunk.theStatus","options":_vm.options.dropStatusList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(_vm.content.muscoskeletalMotorAssessment.weaknessInTrunk.theStatus),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment.weaknessInTrunk, "theStatus", $$v)},expression:"content.muscoskeletalMotorAssessment.weaknessInTrunk.theStatus"}})],1):_vm._e(),(
              _vm.handleLogicStatement('weaknessInTrunk.dateIfNew', 'visibility')
            )?_c('div',{staticClass:"col"},[_c('datepicker',{attrs:{"label":"Date of Diagnosis","name":"weaknessInTrunk.dateIfNew","placeholder":"Enter Date of Diagnosis","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement('weaknessInTrunk.dateIfNew', 'disable')},on:{"changeEvent":_vm.updateData},model:{value:(_vm.content.muscoskeletalMotorAssessment.weaknessInTrunk.dateIfNew),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment.weaknessInTrunk, "dateIfNew", $$v)},expression:"content.muscoskeletalMotorAssessment.weaknessInTrunk.dateIfNew"}})],1):_vm._e(),(
              _vm.handleLogicStatement('weaknessInTrunk.isPriorPompe', 'visibility')
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Was diagnosis made prior to of Pompe Therapy?","name":"weaknessInTrunk.isPriorPompe","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment.weaknessInTrunk
                  .isPriorPompe
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment.weaknessInTrunk
                  , "isPriorPompe", $$v)},expression:"\n                content.muscoskeletalMotorAssessment.weaknessInTrunk\n                  .isPriorPompe\n              "}})],1):_vm._e()]),_c('div',{staticClass:"row"},[(
              _vm.handleLogicStatement('patientCurrentlyInAmbulatory', 'visibility')
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Is the patient currently ambulatory","name":"patientCurrentlyInAmbulatory","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .patientCurrentlyInAmbulatory
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  , "patientCurrentlyInAmbulatory", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .patientCurrentlyInAmbulatory\n              "}})],1):_vm._e(),(_vm.handleLogicStatement('patientAmbulatoryStatus', 'visibility'))?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Status","name":"patientAmbulatoryStatus","options":_vm.options.dropStatusList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment.patientAmbulatoryStatus
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment, "patientAmbulatoryStatus", $$v)},expression:"\n                content.muscoskeletalMotorAssessment.patientAmbulatoryStatus\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement(
                'datePatientAmbulatoryDiagnosis',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('datepicker',{attrs:{"label":"Date of Diagnosis","name":"datePatientAmbulatoryDiagnosis","placeholder":"Enter Date of Diagnosis","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement(
                'datePatientAmbulatoryDiagnosis',
                'disable'
              )},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .datePatientAmbulatoryDiagnosis
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  , "datePatientAmbulatoryDiagnosis", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .datePatientAmbulatoryDiagnosis\n              "}})],1):_vm._e(),(
              _vm.handleLogicStatement('patientAmbulatoryPriorPompe', 'visibility')
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Was diagnosis made prior to of Pompe Therapy?","name":"patientAmbulatoryPriorPompe","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment.patientAmbulatoryPriorPompe
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment, "patientAmbulatoryPriorPompe", $$v)},expression:"\n                content.muscoskeletalMotorAssessment.patientAmbulatoryPriorPompe\n              "}})],1):_vm._e()]),(
            _vm.handleLogicStatement(
              'patientAmbulatoryAssistanceType',
              'visibility'
            )
          )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Ability","name":"patientAmbulatoryAssistanceType","options":_vm.options.abilityList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .patientAmbulatoryAssistanceType
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  , "patientAmbulatoryAssistanceType", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .patientAmbulatoryAssistanceType\n              "}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[(
              _vm.handleLogicStatement(
                'ambulationWasLostSinceTheLastAssessment',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Ambulation was lost since the last assessment","name":"patientAmbulatoryLost","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment.patientAmbulatoryLost
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment, "patientAmbulatoryLost", $$v)},expression:"\n                content.muscoskeletalMotorAssessment.patientAmbulatoryLost\n              "}})],1):_vm._e(),(_vm.handleLogicStatement('dateAmbulationWasLost', 'visibility'))?_c('div',{staticClass:"col"},[_c('datepicker',{attrs:{"label":"Date Ambulation Was Lost","name":"dateAmbulationWasLost","placeholder":"Enter Date of Diagnosis","logic":_vm.logic,"errors":_vm.errors,"isDisable":_vm.handleLogicStatement(
                'dateAmbulationWasLost',
                'disable'
              )},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment.dateAmbulationWasLost
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment, "dateAmbulationWasLost", $$v)},expression:"\n                content.muscoskeletalMotorAssessment.dateAmbulationWasLost\n              "}})],1):_vm._e()]),_c('div',{staticClass:"row"},[(
              _vm.handleLogicStatement(
                'currentlyUsingAmbulatoryDevice',
                'visibility'
              )
            )?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Currently using ambulatory devices","name":"currentlyUsingAmbulatoryDevice","options":_vm.options.dropList,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment
                  .currentlyUsingAmbulatoryDevice
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment
                  , "currentlyUsingAmbulatoryDevice", $$v)},expression:"\n                content.muscoskeletalMotorAssessment\n                  .currentlyUsingAmbulatoryDevice\n              "}})],1):_vm._e(),(_vm.handleLogicStatement('ambulatoryDevice', 'visibility'))?_c('div',{staticClass:"col"},[_c('dropdown',{attrs:{"label":"Ambulatory Device","name":"ambulatoryDevice","options":_vm.options.ambulatoryDevices,"logic":_vm.logic,"errors":_vm.errors},on:{"changeEvent":_vm.updateData},model:{value:(
                _vm.content.muscoskeletalMotorAssessment.ambulatoryDevice
              ),callback:function ($$v) {_vm.$set(_vm.content.muscoskeletalMotorAssessment, "ambulatoryDevice", $$v)},expression:"\n                content.muscoskeletalMotorAssessment.ambulatoryDevice\n              "}})],1):_vm._e()]),_c('hr'),(_vm.canManagePatient)?_c('div',{staticClass:"text-center buttons mt-2"},[_c('button',{staticClass:"btn btn-sm btn-pompe-primary ml-2",attrs:{"type":"submit"}},[_vm._v(" Continue ")])]):_vm._e()],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }