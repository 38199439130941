<template>
  <div id="fatigue-scale">
    <h5>Fatigue Severity Scale</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem;"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      v-if="getDataSuccess && handleLogicStatement('fatigueSeverityScaleInfo', 'visibility')"
    />

    <b-overlay :show="saveDataLoading">
      <fieldset :disabled="!canManagePatient">
        <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
          <!-- Date of Assessment -->
          <datepicker 
            v-if="handleLogicStatement('dateOfFatigueSeverityScale', 'visibility')"
            v-model="content.fatigueSeverityScale.dateOfFatigueSeverityScale"
            label="Date of Assessment"
            name="dateOfFatigueSeverityScale"
            placeholder="Enter Date of Assessment"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('dateOfFatigueSeverityScale', 'disable')"
            @changeEvent="updateData"
          />

          <hr />

          <table class="table table-bordered table-striped">
            <thead class="th-label">
              <tr>
                <th>No</th>
                <th>Statements</th>
                <th class="text-center" v-for="i in 7" :key="i">{{ i }}</th>
              </tr>
            </thead>
            <tbody class="tr-label">
              <tr v-for="(item, index) in options.severityScale" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ item.text }} <br />
                  <!-- display error messages -->
                  <error-message
                    v-if="!$hasField(item.value, errors) && errors"
                    :errors="errors"
                    :fieldName="item.value"
                  />
                </td>
                <td class="text-center" v-for="i in 7" :key="i">
                  <radiobutton
                    v-model="content.fatigueSeverityScale[item.value]"
                    :radio-id="item.value + i"
                    :name="item.value"
                    :radio-value="i"
                    :logic="logic"
                    :errors="errors"
                    type="single"
                    :show-error="false"
                    @inputEvent="updateData"
                  />
                  <br />
                </td>
              </tr>
            </tbody>
          </table>

          <hr />

          <div
            v-if="canManagePatient"
            class="text-center buttons mt-3"
          >
            <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
              Continue
            </button>
          </div>
        </form>
      </fieldset>
    </b-overlay>
  </div>
</template>

<script>
import FatigueSeverityScaleMixin from './FatigueSeverityScale.mixin';
import { $hasField, $getInfoText } from '@/helper/globalFunction.js';

export default {
  name: 'FatigueSeverityScaleForm',
  props: {
    canManagePatient: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [
    FatigueSeverityScaleMixin
  ],
  data: () => {
    return {
      infoText: '',
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $hasField,
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "fatigueSeverityScale",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "fatigueSeverityScaleInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style></style>
